<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import CentresData from "./centres-data";
import AddCentre from "./add-centre";

import {
  layoutComputed,
  authComputed,
  centresMethods,
  centresComputed,
  provincesMethods,
  provincesComputed,
  regionsMethods,
  regionsComputed,
} from "@/state/helpers";
/**
 * Centres component
 */
export default {
  page: {
    title: "Centres",
    meta: [{ name: "Centres" }],
  },
  components: {
    Layout,
    PageHeader,
    CentresData,
    AddCentre,
  },
  mounted() {
    this.retrieveCentres({})
    .then(async ()=>{
      await this.retrieveRegions();
      await this.retrieveProvinces();
    });
    
  },
  data() {
    return {
      title: "titles.centres.text",

      currentPage: 1,
      perPage: 10,

      busy: false,

      xlsxFile: null,

      loaderDashboard: false,

      centresData: [],

      provincesData: [],
      regionsFilterData: [{ value: null, text: "--All--" }],
      provincesFilterData: [{ value: null, text: "--All--" }],
      centresFilter: {
        regions: null,
        provinces: null,
      },
      regions: [null],
      provinces: [null],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...centresComputed,
    ...provincesComputed,
    ...regionsComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.centres.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...centresMethods,
    ...provincesMethods,
    ...regionsMethods,

    retrieveCentres({
      page, 
      perPage, 
      regions,
      provinces,
    }) {
      this.busy = true;
      return this.getCentresBy({
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        populate: 'province,region', 
        sortBy: '-date,-createdAt',
        regions: regions ? regions : this.centresFilter.regions,
        provinces: provinces ? provinces : this.centresFilter.provinces,
      }).then(() => {
        this.busy = false;
      });
    },
    retrieveRegions() {
      if (this.getRegions.results)
        return this.chargeRegions(this.getRegions.results);
      else
        return this.getRegionsBy({ limit: 100 }).then((regions) => {
          return regions;
        });
    },
    retrieveProvinces(regions) {
      if (this.getProvinces.results)
        return this.chargeProvinces(this.getProvinces.results, regions);
      else
        return this.getProvincesBy({ limit: 100 }).then((provinces) => {
          return provinces;
        });
    },
    chargeRegions(regions) {
      let rData = regions;
      rData.map((obj) => {
        this.regionsFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    chargeProvinces(regions) {
      this.provincesFilterData = [{ value: null, text: "--All--" }];
      let pData = this.getProvinces.results;
      pData.map((obj) => {
        if (regions.includes(obj.region))
          this.provincesFilterData.push({ value: obj.id, text: obj.name });
      });
    },
    filter() {
      this.currentPage = 1;
      this.retrieveCentres({ ...this.centresFilter, page: 1 }).then(() => {
        this.hideFilter();
      });
    },
    clear() {
      this.centresFilter = {
        regions: null,
        provinces: null,
      };
      this.regions = [null];
      this.provinces = [null];
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-centre-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-centre-modal");
    },
    add(centre) {
      this.addCentre(centre)
        .then((centre) => {
          this.makeToast("Add centre for", centre.name+" has been added", "success")
        })
        .catch(error => {
          this.makeToast("Add centre", error, "danger")
        });
    },
    upload() {
      const formData = new FormData();
      formData.append("file", this.xlsxFile);
      this.busy = true
      return this.uploadCentres(formData).then(() => {
        this.busy = false;
        this.currentPage = 1;
        this.retrieveCentres({});
      });
    },
    openUpload(){
      this.$bvModal.show("upload-modal");
    },
    openFilter(){
      this.$bvModal.show("filter-modal");
    },
    hideFilter(){
      this.$bvModal.hide("filter-modal");
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveCentres({});
    },
  },
  watch: {
    getCentres(newVal) {
      this.centresData = newVal.results;
    },
    /* getProvinces(newVal) {
      this.chargeProvinces(newVal.results, this.regions);
    }, */
    getRegions(newVal) {
      this.chargeRegions(newVal.results);
    },
    regions(newVal) {
      let lenRegions = newVal.length;

      if (!newVal[lenRegions - 1] && lenRegions > 1) {
        this.regions = [null];
        this.centresFilter.regions = null;
      } else if (newVal[lenRegions - 1] && newVal.includes(null)) {
        var filteredRegions = this.regions.filter(function (item) {
          return item != null;
        });
        this.regions = filteredRegions;
      } else if (newVal[lenRegions - 1] && !newVal.includes(null)) {
        this.centresFilter.regions = newVal;
      } else if (newVal.length == 0) {
        this.regions = [null];
        this.centresFilter.regions = null;
      }
      this.chargeProvinces(newVal);
    },
    provinces(newVal) {
      let lenProvinces = newVal.length;

      if (!newVal[lenProvinces - 1] && lenProvinces > 1) {
        this.provinces = [null];
        this.centresFilter.provinces = null;
      } else if (newVal[lenProvinces - 1] && newVal.includes(null)) {
        var filteredProvinces = this.provinces.filter(function (item) {
          return item != null;
        });
        this.provinces = filteredProvinces;
      } else if (newVal[lenProvinces - 1] && !newVal.includes(null)) {
        this.centresFilter.provinces = newVal;
      } else if (newVal.length == 0) {
        this.provinces = [null];
        this.centresFilter.provinces = null;
      }
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div  class="d-flex flex-row-reverse mb-2">
      <div class="text-right">
        <b-button type="button" class="mr-2" variant="light" @click="openFilter"
          ><i class="ri-filter-3-line"></i>
        </b-button>
        <b-modal 
          id="filter-modal"
          ref="filter-modal"
          hide-footer
          size="md"
          :title="this.$t('modals.filterPlanif.text')"
        >
          <div class="row">
            <div class="col-12" data-app>
              <label for="regionSh">{{ $t("modals.region.text") }}</label>
              <v-select
                id="regionSh"
                v-model="regions"
                :items="regionsFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <div class="col-12" data-app>
              <label for="provinceSh">{{ $t("modals.province.text") }}</label>
              <v-select
                id="provinceSh"
                v-model="provinces"
                :items="provincesFilterData"
                :menu-props="{ maxHeight: '200' }"
                item-text="text"
                item-value="value"
                multiple
              ></v-select>
            </div>
            <div class="col-12 text-center">
              <b-button type="button" variant="light" @click="filter"
                >{{$t("buttons.filter.text")}}
              </b-button>
            </div>
          </div>
        </b-modal>
      </div>
      <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addCentre.text")}}
        </b-button>
        <AddCentre @addCentre="add" :regions="getRegions.results" :provinces="getProvinces.results" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <CentresData v-bind:centres="centresData" :regions="getRegions.results" :provinces="getProvinces.results" :busy="busy" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getCentres.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>