<script>
/**
 * Centres Data component
 */
import EditCentre from "./update-centre";
import { authComputed, centresMethods } from "@/state/helpers";
export default {
  props: ["regions", "provinces", "centres", "busy", "loaderStatus"],
  components: {
    EditCentre,
  },
  data() {
    return {
      centre: null,
      centresList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != 'USER' && this.loggedIn.user.role != 'OPERATOR') {
        return [
          { key: "name", sortable: false, label: this.$t("dataTable.name.text"), class: "tdClass" },
          { key: "region", sortable: false, label: this.$t("dataTable.region.text"), class: "tdClass" },
          { key: "province", sortable: false, label: this.$t("dataTable.province.text"), class: "tdClass" },
          { key: "actions", sortable: false, label: this.$t("dataTable.tools.text"), class: "tdClass" },
        ];
      } else {
        return [
          { key: "name", sortable: false, label: this.$t("dataTable.name.text"), class: "tdClass" },
          { key: "region", sortable: false, label: this.$t("dataTable.trgion.text"), class: "tdClass" },
          { key: "province", sortable: false, label: this.$t("dataTable.province.text"), class: "tdClass" },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...centresMethods,
    showModalEdit() {
      this.$bvModal.show("edit-centre-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-centre-modal");
    },
    edit(centre) {
      this.updateCentre(centre)
        .then(() => {
          this.makeToast(
            "Update centre",
            "centre has been updated",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Update centre", error, "danger");
        });
    },
    passCentre(type, centre) {
      if (type == "edit") {
        this.centre = centre;
        this.showModalEdit();
      } else if (type == "delete") {
        this.$swal
          .fire({
            title: this.$t('modals.askValidDelete.text'),
            text: this.$t('modals.cantRevert.text'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("buttons.validDelete.text"),
            cancelButtonText: this.$t("buttons.cancel.text"),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.delCentre(centre.id)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                "Deleted!",
                "The centre has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            this.makeToast("Alert Error", error, "danger");
          });
      }
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    rowClicked(record) {
      //this.$router.push({name:'alert', params: { serial: record.serial }})
      console.log(record);
    },
  },
  watch: {
    centres(newVal) {
      this.centresList = []
      newVal.forEach(obj=>{
        this.centresList.push({
          ...obj,
          region: obj.region.name,
          province: obj.province.name,
        })
      })
    },
  },
};
</script>

<template>
  <div>
    <div
      class="table-responsive mb-3 bg-white rounded-lg"
      style="min-height: 350px"
    >
      <b-table
        class="mb-0"
        hover
        show-empty
        centred
        :busy="busy"
        :empty-text="$t('dataTable.noCentres.text')"
        :items="centresList"
        :fields="fieldsComputed"
        v-bind:style="{ cursor: `pointer` }"
        stacked="sm"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <strong>{{$t('dataTable.loading.text')}}</strong>
          </div>
        </template>
        <template v-slot:cell(date)="row">
          <div 
            :style='{
              "color": row.item.level == 3 ? "red" : row.item.level == 2 ? "orange" : "green"
            }'
          >
            <strong>{{row.item.date}}</strong>
          </div>
        </template>
        <template v-slot:cell(actions)="data">
          <div class="d-inline-flex align-items-center">
            <b-button
              type="button"
              class="mr-2"
              variant="light"
              @click="passCentre('edit', centres[data.index])"
              >Edit
            </b-button>
            <b-button
              type="button"
              variant="danger"
              @click="passCentre('delete', centres[data.index])"
              >Delete
            </b-button>
          </div>
        </template>
      </b-table>
      <EditCentre v-bind:centre="centre" :regions="regions" :provinces="provinces" @editCentre="edit" />
    </div>
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>