<template>
  <div>
    <b-modal
      id="edit-centre-modal"
      ref="edit-centre-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updateCentre.text')"
    >
      <!-- Region -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="region">{{$t("modals.region.text")}}</label>
          <b-form-select
            id="province"
            :options="regionsList"
            v-model="centreEdit.region"
            class="mb-1"
            :state="err.region ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.region }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Province -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="province">{{$t("modals.province.text")}}</label>
          <b-form-select
            id="province"
            :options="provincesList"
            v-model="centreEdit.province"
            class="mb-1"
            :state="err.province ? false : null"
          ></b-form-select>
          <b-form-invalid-feedback>
            {{ err.province }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- Centre -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="name">{{$t("modals.name.text")}}</label>
          <b-form-input
            id="name"
            v-model="centreEdit.name"
            class="mb-1"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{$t("buttons.update.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['centre', 'regions', 'provinces'],
  created() {
    if(this.regions){
      let regionsData = this.regions;
      regionsData.map((obj) => {
          this.regionsList.push({ value: obj.id, text: obj.name });
      });
    }
    if(this.provinces){
      this.provincesData= this.provinces
    }
  },
  data() {
    return {
      centreEdit: {
        name: null,
        region: null,
        province: null,
      },
      err: {
        name: null,
        region: null,
        province: null,
      },
      regionsList: [{ value: null, text: this.$t("titles.regions.text"), disabled: true }],
      provincesList: [{ value: null, text: this.$t("titles.provinces.text"), disabled: true }],

      provincesData: null,
    };
  },
  watch: {
    centre(newVal) {
      this.centreEdit = {
        centreId: newVal.id,
        name: newVal.name,
        region: newVal.region.id,
        province: newVal.province.id,
      };
      console.log(this.centreEdit);
      this.retrieveProvinces(newVal.region)
    },
    provinces(newVal){
      this.provincesData= newVal
    },
    regions(newVal) {
      let regionsData = newVal;
      regionsData.map((obj) => {
          this.regionsList.push({ value: obj.id, text: obj.name });
      });
    },
    'centreEdit.region'(newVal){
      this.retrieveProvinces(newVal)
    },
  },
  methods: {
    retrieveProvinces(region) {
      this.provincesList = [{ value: null, text: this.$t("titles.provinces.text"), disabled: true }];
      let provinces = this.provincesData;
      provinces.map((obj)=>{
        if(obj.region == region) this.provincesList.push({ value: obj.id, text: obj.name });
      })
    },
    cancel() {
      this.clear();
    },
    clear() {
      /* this.centreEdit = {
        centreId: null,
        name: null,
      }; */
      this.err = {
        name: null,
        region: null,
        province: null,
      };
    },
    edit(ok) {
      if (!this.centreEdit.name) this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.centreEdit.region) this.err.region = this.$t("msgError.obligField.text");
      else this.err.region = "";

      if (!this.centreEdit.province) this.err.province = this.$t("msgError.obligField.text");
      else this.err.province = "";

      if (!this.err.name || !this.err.region|| !this.err.province) {
        if (this.centreEdit.name != this.centre.name || 
            this.centreEdit.region != this.centre.region || 
            this.centreEdit.province != this.centre.province) {
          ok();
          this.$emit("editCentre", this.centreEdit);
        } else {
          ok();
        }
      }
    },
  },
};
</script>